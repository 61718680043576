import React, { useState } from 'react';
import { SelectDropdown } from '@gsa/afp-component-library';
import { PropTypes } from 'prop-types';
import TagLookup from './tag-lookup';
import VINLookup from './vin-lookup';
import PlateLookup from './plate-lookup';
import { ErrorMessage } from '@gsa/afp-component-library';

const VehicleSearch = ({ onSelect, disabled }) => {
  const [selectedSearchOption, setSelectedSearchOption] = useState('tag');
  const [error, setError] = useState('');

  function handleErrorMessage(message) {
    setError(message);
  }

  return (
    <div data-testid="vehicle-search" id="vehicle-search-wrapper">
      <div>{error && <ErrorMessage>{error}</ErrorMessage>}</div>
      <div className="grid-row margin-bottom-neg-3">
        <h3 className="margin-y-0">Find a vehicle</h3>
      </div>
      <div className="grid-row">
        <div className="grid-col-7">
          {selectedSearchOption === 'tag' && (
            <TagLookup
              onSelect={onSelect}
              disabled={disabled}
              handleError={handleErrorMessage}
            />
          )}

          {selectedSearchOption === 'vin' && (
            <VINLookup
              onSelect={onSelect}
              disabled={disabled}
              handleError={handleErrorMessage}
            />
          )}

          {selectedSearchOption === 'state_plate' && (
            <PlateLookup
              onSelect={onSelect}
              disabled={disabled}
              handleError={handleErrorMessage}
            />
          )}
        </div>
        <div className="grid-col-5 margin-top-1">
          <SelectDropdown
            name="vehicle-search-selection"
            data-testid="vehicle-search-selection"
            options={[
              {
                value: 'tag',
                label: 'federal license plate',
                defaultValue: true,
              },
              { value: 'vin', label: 'VIN' },
              { value: 'state_plate', label: 'state plate' },
            ]}
            onChange={(event) => {
              const selectedValue = event.target.value;
              setSelectedSearchOption(selectedValue);
            }}
          />
        </div>
      </div>
    </div>
  );
};

VehicleSearch.propTypes = {
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
};
VehicleSearch.defaultProps = {
  onSelect: undefined,
  disabled: false,
};

export default VehicleSearch;
